.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.bottomContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    width: 65%;
    height: 56%;
    border-radius: 10px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.name {
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding-top: 4rem;
}

.topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 15.5rem;
    z-index: 1;
}

.freqImageContainer {
    width: 60%;
    border-radius: 10px;
    overflow: hidden;
}

.value {
    font-family: 'Postnobillscolombo';
    font-size: 25px;
    color: white;
    position: absolute;
    top: 30%;
}

.removeFreqContainer {
    position: absolute;
    top: -10px;
    right: 48px;
    cursor: pointer;
    z-index: 1000;
}

.cardContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.gradientOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: 1;
}

.gradientImageContainer {
    display: inline-block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.bianuralValue {
    position: absolute;
    z-index: 2;
    font-size: 50px; 
    color: white;
    font-weight: 700;
  }