.loginPage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh;
}

.mainBox{
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    background-image: linear-gradient(to right,rgba(255, 255, 255, -10),rgba(185, 106, 137, 0.20), rgba(167, 124, 141, 0));
}

.leftSideContainer{
    background: white;
    display: grid;
    height: 100vh;
    width: 50vw;
}

.rightSideContainer{
    height: 100vh;
    width: 40vw;
    display: flex;
    justify-content:flex-start;
    align-items: center;

}

.leftSideWrapper{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.rightSideWrapper{
    display: flex;
    justify-content:center;
    overflow-y :auto;
    flex:0.7;
    /* background-image: linear-gradient(rgba(185, 106, 137, 0.61),rgba(167, 124, 141, 0)); */
}

.login-wrapper{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.companylogo{
    margin-top: 5%;
    cursor:default;
}

.loginLabel{
    font-size: 45px;
    color: #FFFFFF;
    font-family: 'Wix Madefor Text';
    font-weight: 700;
}

.loginContent{
    font-size: 20px;
    color: #FFFFFF;
    font-family: 'Wix Madefor Text';
    text-align: center;
    font-weight: 500;
    align-self: center;
}

.labelStyle{
    color:#F3F3F3;
    font-size: 20px;
    font-weight: 500;
}

.emailTextBox{
    width: 80%;
    margin-top: 10px;
}

.passwordTextBox{
    margin-top: 10px;;
    margin-bottom: 3vh;
    width: 80%;
}
.MuiFormLabel-root.Mui-focused {
    border-color:#F3F3F3 !important;
    color: white;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:#F3F3F3 !important;
  }
  

.submitButton{
    display: flex;
    justify-content: center;
    width: 80%;
    font-size: 15px;
    padding: 15px 40px;
    border-radius: 40px ;
    background-image: linear-gradient(to right,rgba(100, 80, 70, 0.60),rgba(123, 107, 123, 0.96));
}

.submitButton:hover{
    transition: all 0.2s ease-in-out; 
}
.submitButton:active{
    border:2px solid black;
}

.showError{
    visibility: visible;
    color: red;
    font-size: 20px;
    align-self: center;
}

.hideError{
    font-size: 12px;
    visibility: hidden;
}
.loginDoodle{
    height: 100%;
    width: 100%;
}
.leftSideDetails{
    height: 100%;
    width : 60%;
}

/* 600px when viewport enabled */
@media only screen and (max-device-width: 600px) {
    .loginPage{
        padding-top: 25%;
        padding-bottom: 30%;
    }
    .mainBox{
        display:flex;
        flex-wrap: wrap;
        min-height: 100%;
        width: 85%;
        height: max-content;
        border-radius: 5px;
    }
    .leftSideWrapper{
        width: 85vw;
        border-radius: 0px;
    }
    .rightSideWrapper{
        width: 100%;
        border-radius: 0px;
        padding-bottom: 5%;
    }
    .loginDoodle{
        height: 40%;
    }
    .leftSideDetails{
        height: 50vh;
        padding: 10%;
    }
    .login-wrapper{
        padding-top: 20%;
    }
    .emptyContainer{
        height: 200px;
    }
}
