.categoryTitle{
    font-size: 32px;
    font-weight: 700;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.type{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0.5rem 3rem;
    gap: 2rem;
}

.subcategoryTitle{
    font-size: 24px;
    font-weight: 700;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.categoryList{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
    gap: 8rem;
    width: 100%;
}
.categoryImage {
    width: 90px; 
    height: 90px; 
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    display: block;
}
.categoryItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 1rem;
    width: 100%;
}
.songGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 3rem; 
    margin-top: 10px; 
    align-items: start;
    width: 100%;
}

.songItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.songTitle {
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    gap: 10px; 
    width: 100%;
}

.imageSong{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    justify-content: center;
}

.songContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    cursor: pointer;
    /* background-color: #888383; */
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    width: 60%;
    height: 10rem;
    font-size: 20px;
    position: relative;
}

.songContainer:hover {
    transform: translateY(-5px);
    opacity: 0.7;
}
.removeContainer{
    position: absolute;
    top: -13px;
    right: -5px;
    cursor: pointer;
    z-index: 1000;
}


.imageContainer {
    position: relative;
    display: inline-block;
}


.editIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.imageContainer:hover .editIcon {
    opacity: 0.7;
}
.storyContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    width: 90%;
    font-size: 12px;
    position: relative;
}
.storyContainer:hover {
    transform: translateY(-5px);
    opacity: 0.7;
}
.storyDetails{
    width: 100%;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    color: #aaa3a3;
}
.imageStory{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-start; 
    justify-content: center;
}

.imagePodcast{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    justify-content: center;
}
.storyTitle{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-start; 
    justify-content: center;
    position: absolute;
    bottom: 0;
    background-color: rgba(46, 37, 37, 0.589);
}
.storyDesc{
    display: block;
    text-align: left;
    font-size: 16px;
}

.songContent{
    display: flex;
    gap: 5px;
    width: 100%;
}
.editText{
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    position: relative;
    bottom: 4px;
}
/**
 * CSS class that sets the text color to gray and the background color to red.
 * This class is likely used to indicate a "false" or negative status in the UI.
 */

.statusFalseColor{
  color: #888383;
}
.songCheckbox {
    accent-color: green; /* Change this to your desired color */
}

.podcastDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.genres{
    font-size: 16px;
    font-style: italic;
}
.podcastTitle{
  font-size: 22px;
  font-weight: 500;
}

.hostname{
    font-size: 18px;
}