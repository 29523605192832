
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }

.extra{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8); 
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1500;
    }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.modal-content {
    background-color: #333; 
    padding: 20px; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
    width: auto; 
    max-width: 90%; 
    position: relative; 
    z-index: 1001; 
    overflow-y: auto;
    max-height: 80%;
}

.modal-content h2 {
    color: #fff; 
}

.modal-close-button {
    position: absolute;
    top: 10px; 
    right: 10px; 
    background-color: transparent; 
    border: none;
    color: #fff;
    font-size: 24px; 
    cursor: pointer;
}

.modal-close-button:hover {
    color: #ccc;
    text-decoration: none;
}

.addModal{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    justify-content: center;
}
.submitButton1{
    display: flex;
    justify-content: center;
    border-radius: 10px ;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    background-color:#FFFFFF;
    border: none;
    cursor: pointer;
}
.cancelButton{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF80;
    background-color:transparent;
    border: none;
}

.frequencyInfo{
    width: 25%;
    height: 60%;
    padding: 20px; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
    position: relative; 
    z-index: 1001; 
}

.modalButtons{
    display: flex;
    justify-content: space-around;
    margin: 10px;
}
.buttonStyle{
    border-radius: 40px;
    padding: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: transparent;
    border: 2px solid white;
    width: 40%;
    cursor: pointer;
}
.description {
    white-space: pre-line;
    margin: 10px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}