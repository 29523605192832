.topBar{
    padding:1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title{
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    color: #FFFFFF;
    padding-right: 2.5rem;
}
.headingList{
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #FFFFFF;
    gap: 2.5rem;
}